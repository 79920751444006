import React, { useState, useEffect } from 'react'
import { graphql, Link } from "gatsby"
import { Camera } from "react-feather"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { motion, useViewportScroll, useSpring, useTransform, useMotionValue } from "framer-motion"
import { VictoryPie } from "victory"
import Loadable from "@loadable/component"

import Demo from "../components/Demo"
import DemoTable from "../components/DemoTable"
import FeatureTile from "../components/FeatureTile"
import FeatureBlock from "../components/FeatureBlock"
import Layout from "../components/layouts/Layout"
import FAQ from "../components/FAQ"
import Button from "../components/Button"
import SwitchNav from "../components/SwitchNav"
import DemoStyles from "../components/Demo.module.scss"

const PlantModel = Loadable(() => import("../components/PlantModel"), {
  fallback: <div style={{height:18+'rem', paddingTop: 0+'px'}}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{margin: 'auto auto', height: 50+'px'}}>
      <path opacity=".5" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"/>
      <path style={{fill: '#333333'}} d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
})

const VideoDemo = ({ children }) => {
  return (
  <>
    <>{children}</>
    <motion.div
      animate={{ opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.5 }}
      className="bg-black overflow-hidden" 
      style={{
          borderRadius: 16+'px',
          height: 18+'rem'
      }}>
        {/* <video 
          autoplay="true" 
          muted="true" 
          loop="true" 
          playsinline="true"
          style={{
              borderRadius: 14+'px'
          }}>
          <source src="https://api.counterset.com/f/e280d69d-9fcc-438e-8ffb-c8565984439d" type="video/mp4" />
        </video> */}
        <PlantModel />
      </motion.div>
      <div className="pt-4 pb-1">
        <span style={{fontSize: 20+'px', marginLeft:8+'px'}}>Manitoba CBD Range</span>
        <div className={`${DemoStyles.verify}`}></div>
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Ingredients</span>
      <div className="pb-5 text-sm" style={{marginLeft:8+'px'}}>
        Beeswax, almond oil, willow bark, tea leaf extract.
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Terpenes</span>
      <VictoryPie
        data={[
          { x: 1, y: 70, label: "Myrcene" },
          { x: 2, y: 20, label: "Linalool" },
          { x: 2, y: 10, label: "Humulene" },
        ]}
        colorScale="qualitative"
    />
  </>
  )
}

const AuthDemo = ({ }) => {
  return (
  
  <>
  <motion.div
      animate={{ opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.2 }}
    >
  <div style={{ position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.2)',
                zIndex: 999,
                opacity: 1}} >
      <div style={{top: '158px', 
                  left: '30px',
                  width: '300px',
                  height: '300px',
                  position: 'relative',
                  overflow: 'hidden',
                  boxSizing: 'border-box',
                  backgroundColor: 'white',
                  textAlign: '',
                  borderRadius: '16px',
                  boxShadow: '0 20px 60px -2px rgba(27, 33, 58, 0.4)',
                  padding: 0}} >
          <div style={{fontWeight: 600, fontSize: '22px', padding: '18px', textAlign:'center'}}>Manitoba Wellness Ltd.</div>
          <div style={{backgroundColor: '#0b2', padding: '12px 12px 12px 18px', color: '#fff'}}>
            <div style={{textAlign:'center'}}>
              <span class="verifiedtitle">
                Verified Seller
              </span>
            </div></div> 
        <div style={{padding: '12px 12px 12px 18px'}}>
          <div ><div style={{fontWeight: 600, paddingTop: '6px'}}>
          Office Address
        </div> <div class="content">
          191 Elk City Road
          <br />
          Indianapolis, Indiana 46205
        </div></div> <div ><div style={{fontWeight: 600, paddingTop: '12px'}}>
          Brands
        </div> <div class="content">
          Manitoba Flower
        </div></div></div>

      </div>
  </div>
  </motion.div>
  </>
  )
}

const LabDemo = ({ }) => {
  return (
  <>
      <div className="pt-4 pb-1">
        <span style={{fontSize: 20+'px', marginLeft:8+'px'}}>Manitoba CBD Balm</span>
        <div className={`${DemoStyles.verify}`}></div>
      </div>
      <div className="pb-5 text-sm" style={{marginLeft:8+'px'}}>
          This densely packed bud is a cluster of calyces.
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Test Results</span>
      <DemoTable items={[
        {
          title: "Pesticides",
          content: "Not Detected"
        },
        {
          title: "Heavy Metals",
          content: "Not Detected"
        },
        {
          title: "Pack Date",
          content: "06/07/2019"
        },
        {
          title: "Expiration Date",
          content: "10/12/2019"
        },
        {
          title: "Total CBD Content",
          content: "22 mg/g"
        },
        {
          title: "Batch Number",
          content: 'B12343344'
        },
        {
          title: "Batch Size",
          content: '4500 units'
        },
        {
          title: "",
          content: "This product contains not more than three-tenths percent (0.3%) totaldelta-9-tetrahydrocannabinol (THC), including precursors, by weight."
        }
      ]} />

      <div style={{marginTop: '24px'}}>
      <span style={{fontSize: 16+'px', marginTop: '18px', marginLeft:8+'px'}}>Certificate of Analysis</span>
      </div>
    
      <a href="https://api.counterset.com/f/ae7e7a41-5307-4883-b2b7-05a2511b3af0"
        target="_blank" className={`${DemoStyles.download}`}>
        <div style={{                  
                  }} >
      
        Download File
      </div>
        </a>

  </>
  )
}

const Header = ({ translateY }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  return (<>
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex sm:w-1/2">
          <div className="pt-12 text-left">
            <h1 className="text-6xl text-black" style={{maxWidth: 400+'px', lineHeight: 90+'px', marginBottom: 30}}>Features</h1>
            <p className="text-lg text-gray-800 font-normal max-w-lg">
              CounterSet makes it simple to create secure, mobile first websites for products.
            </p>

            <div className="pt-12 pb-12">
              <a href="mailto:will@counterset.com" className="items-center btn btn-black">Request a demo</a>
            </div>
          </div>
        </div>
        <motion.div 
          className="hidden sm:flex sm:w-1/2 justify-end relative pt-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 0.5 }}
          style={{ translateY: translateY }}
        >
          <Demo>
            {selectedItem == 0 && <VideoDemo></VideoDemo>}
            {selectedItem == 1 && <VideoDemo><AuthDemo></AuthDemo></VideoDemo>}
            {selectedItem == 2 && <LabDemo></LabDemo>}
          </Demo>
        </motion.div>
      </div>
    </div>
  </div>
  <div className="w-full max-w-screen-xl mx-auto px-6 pt-12">
    <div className="flex flex-wrap flex-row">
      <div className="w-full flex flex-col sm:w-1/2">
        <SwitchNav
          selectedCallback={(i) => setSelectedItem(i)}
          items={[
            {
              title: 'Create captivating content',
              description: 'Engage your customers with product images, video and Augmented Reality content.'
            },
            {
              title: 'Prove authenticity',
              description: 'Prove to your customers that they are buying an authentic product with unique item level verification.'
            },
            // {
            //   title: 'Inform purchase decisions',
            //   description: 'Empower your customers to make informed purchase decisions with detailed provenance and supply chain information.'
            // },
            {
              title: 'Demonstrate compliance',
              description: 'Easily add batch level regulatory and safety information to every SKU for every jurisdiction.'
            }
          ]}
        ></SwitchNav>
      </div>
    </div>
  </div>
</>)}

export default ({data}) => {
  const { scrollYProgress, scrollY } = useViewportScroll()
  let scrollHead = useSpring(useTransform(scrollY, v => {
    return v < 270 ? v : 270
  }), { damping: 10, stiffness:20 })

  return (
  <Layout header={<Header translateY={scrollHead}></Header>}>
    <Helmet>
      <title>Features</title>
      <meta name="description" content="CounterSet makes it simple to create secure, mobile first websites for products." />
      <meta property="og:description" content="CounterSet makes it simple to create secure, mobile first websites for products." />
    </Helmet>
    <div className="flex flex-row flex-wrap my-4 mt-16">
      <FeatureTile title={"Authorized stockists"}>
        <p>
          Add a map of authorized stockists near the customer’s current location for convenient re-ordering.
        </p>
      </FeatureTile>
      <FeatureTile title={"Beyond the label"}>
        <p>
          Extend your labels by adding step-by-step video instructions, recipes and coupons.
        </p>
      </FeatureTile>
      <FeatureTile title={"Social integration"}>
        <p>
          Social integration allows customers to share and interact with your brand after purchase.
        </p>
      </FeatureTile>
    </div>
    <h2 className="font-bold text-4xl mt-8 mb-8">
      Powerful analytics and creation tools.
    </h2>
    <FeatureBlock
      title="Identify patterns in purchase activity"
      img={(<Img fluid={data.identify.fluid}></Img>)}
    >
      <p className="mb-4">
        CounterSet tracks consumer scans of your products, letting you see SKU interest in real-time, down to the individual customer level.
      </p>
      <p className="mb-4">
        Geo-location of customer scans lets you discover customer hotspots and determine which stores should be stocking your products.
      </p>
    </FeatureBlock>

    <FeatureBlock
      title="Create product websites easily with no code required"
      img={(<Img fluid={data.template.fluid}></Img>)}
      reverse
    >
      <p className="mb-4">
        CounterSet gives your team the ability to build a new product website for every SKU without code or developers.
      </p>
      <p className="mb-4">
        Build and deploy detailed smart labels instantly with label templates.
      </p>
      <ul className="tick-list">
        <li>Promote in-store with video and photo galleries</li>
        <li>Engage customers with Augmented Reality content</li>
        <li>Create detailed tables and charts</li>
        <li>Attach files and photos</li>
        <li>Build maps and links for re-ordering</li>
        <li>Talk in your customer's language with label translations</li>
      </ul>

    </FeatureBlock>

    <FeatureBlock
      title="Scale to thousands of SKUs effortlessly"
      img={(<Img fluid={data.batch.fluid}></Img>)}
    >
      <p className="mb-4">
        CounterSet Batch functionality lets you create millions of unique codes with just a few clicks. Use unique codes to identify counterfeiting and gray market distribution, with minimal changes to your supply chain.
      </p>
      <p className="mb-4">
        Attach and change supply chain information for each individual code or batch. Prove to your customers and to regulators, that your product is safe and authentic.
      </p>
    </FeatureBlock>
    
    <div className="flex flex-row flex-wrap my-4">
      <FeatureTile title={"Dynamic Labelling"}>
        <p>
          CounterSet batch codes let you modify your product packaging dynamically at any time.
        </p>
        <ul className="mt-4 ml-2 tick-list">
          <li>
            Modify brand strategy in real time
          </li>
          <li>
            Open up foreign markets
          </li>
          <li>
            Adapt to changing regulations
          </li>
        </ul>
      </FeatureTile>
      <FeatureTile title={"CounterSet Verified"}>
        <p>
          CS Verified is a cryptographically verifiable audit trail for CounterSet powered by Amazon Quantum Ledger.
        </p>
        <ul className="mt-4 ml-2 tick-list">
          <li>
            Prove and validate data integrity
          </li>
          <li>
            Secured product provenance
          </li>
          <li>
            Detailed change history
          </li>
        </ul>
      </FeatureTile>
      <FeatureTile title={"API and POS integration"}>
        <p>
          CounterSet can be integrated directly into POS and backend systems for additional protection and reporting. 
        </p>
        <ul className="mt-4 ml-2 tick-list">
          <li>
            Mark shipped products as sold
          </li>
          <li>
            Improve demand forecasting
          </li>
          <li>
            Connect ERP and analytics tools
          </li>
        </ul>
      </FeatureTile>
    </div>
  </Layout>
)}

export const query = graphql`
query FeatureImages {
  identify: imageSharp(fluid: {originalName: {eq: "screen-identify.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  batch: imageSharp(fluid: {originalName: {eq: "screen-batch.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  template: imageSharp(fluid: {originalName: {eq: "screen-template.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
`